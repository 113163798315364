import React from 'react'
import './People.scss'
import { useGetPeople } from "generated/person/person"
import Loader from 'components/Loader/Loader'
import Person from 'components/Person/Person'
import PageHeader from "components/PageHeader/PageHeader"
import Divider from "components/Divider/Divider"
import { useTranslation } from 'react-i18next'

const People = () => {
    const { t, i18n } = useTranslation()
    const { data: response, isLoading } = useGetPeople({
        "populate": "avatar",
        locale: i18n.language.split("-")[0]
    }, {
        query: {
            refetchOnWindowFocus: false,
        }
    })

    if (!response) return null

    const { data } = response.data

    if (!data) return null

    return (
        <div className={'people'}>
            <PageHeader title={t("peoplePageTitle")}/>
            <Divider margin={'1rem'}/>
            {isLoading ?
                <Loader/>
                :
                <div className="people__body">
                    {
                        data.map((person) => {
                            return <Person {...person} key={person.id}/>
                        })
                    }
                </div>
            }
        </div>
    )
}

export default People