import React, { useState } from 'react'
import './SeeMore.scss'
import AlbumCard from "components/AlbumCard/AlbumCard"
import { Album } from "model"
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { strapiAxios } from 'api'
import qs from 'qs'

interface ISeeMore {
    album: Album | undefined
    id: string | undefined
}

const SeeMore: React.FC<ISeeMore> = (props) => {
    const { album, id } = props
    const { t } = useTranslation()
    const countries = album?.countries?.data?.[0]?.attributes?.name
    const genres = album?.genres?.data?.[0]?.attributes?.name
    const instruments = album?.instruments?.data?.[0]?.attributes?.name
    const nationalities = album?.nationalities?.data?.[0]?.attributes?.name
    const [currentTag, setCurrentTag] = useState<number>(0)

    //  countries, genres, instruments, nationalities
    const tagArray: Array<{
        label: string,
        id: number | undefined,
        value: string
    }> = [
        {
            label: "countries",
            id: album?.countries?.data?.[0]?.id,
            value: countries as string
        },
        {
            label: "genres",
            id: album?.genres?.data?.[0]?.id,
            value: genres as string
        },
        {
            label: 'instruments',
            id: album?.instruments?.data?.[0]?.id,
            value: instruments as string
        },
        {
            label: "nationalities",
            id: album?.nationalities?.data?.[0]?.id,
            value: nationalities as string
        }]

    const { data: albums } = useQuery({
        queryKey: ["recommendations", currentTag, id, tagArray],
        queryFn: async () => {
            if (!tagArray[currentTag].id) {
                setCurrentTag((prev) => prev + 1)
            }
            const query = qs.stringify(
                {
                    populate: "*",
                    "pagination[pageSize]": 5,
                    filters: {
                        id: {
                            $ne: id
                        },
                        [tagArray[currentTag].label]: {
                            id: {
                                $eq: tagArray[currentTag].id
                            }
                        }
                    }
                })
            const response = await strapiAxios.get(`/api/albums?${query}`)
            const albums = response.data.data
            if (!albums.length || albums.length > 5) {
                setCurrentTag((prev) => prev + 1)
            } else {
                return albums
            }
        },
    })

    return (
        <div className="see-more">
            {albums?.length ?
                <>
                    <div className={"fontSize-m fontWeight-bold"}>{t("seeMoreFound", {
                        tag: tagArray[currentTag].value
                    })}</div>
                    <div className={"see-more__grid"}>
                        {albums?.map((album: any) => {
                            return (
                                <AlbumCard
                                    key={album.id}
                                    {...album}
                                />
                            )
                        })}
                    </div>
                </>
                :
                <div
                    className={"fontSize-s"}
                >
                    {t("seeMoreNotFound", {
                        tag: tagArray[currentTag].value
                    })}
                </div>
            }
        </div>
    )
}

export default SeeMore