import React, { useEffect, useState } from 'react'
import Navigation from 'components/Navigation/Navigation'
import './Header.scss'
import Button from "components/Button/Button"
import { Link, useLocation } from "react-router-dom"
import Burger from 'assets/icons/burger.svg'
import BurgerClose from 'assets/icons/burger-close.svg'
import NavLink from "components/NavLink/NavLink"
import { navigation } from "router"
import Divider from "components/Divider/Divider"
import { useWindowSize } from "hooks"
import { getStyles } from 'helpers'
import { useTranslation } from 'react-i18next'
import Logo from 'components/Logo/Logo'

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
    const { t, i18n } = useTranslation()

    const languages = [
        {
            label: "EN",
            value: "en-US"
        },
        {
            label: "RU",
            value: "ru-RU"
        }
    ]


    const styles = getStyles("header", [
        { className: "open", isTrue: isMenuOpen }
    ])
    const location = useLocation()
    const { width } = useWindowSize()

    useEffect(() => {
        setIsMenuOpen(false)
        if (width > 1280) {
            setIsMenuOpen(false)
        }
    }, [
        location,
        width
    ])

    return (
        <header className={styles}>
            <div className={"header__inner"}>
                <Logo />
                <Navigation/>
                <div className={"header__actions"}>
                    <select
                        name="language"
                        id=""
                        onChange={(event) => {
                            i18n.changeLanguage(event.target.value)
                        }}
                        defaultValue={i18n.language}
                    >
                        {languages.map((language, index) => {
                            return (
                                <option
                                    key={index}
                                    value={language.value}
                                >
                                    {language.label}
                                </option>
                            )
                        })}
                    </select>
                    <Link
                        to={"https://linktr.ee/world.music.geek"}
                        target={"_blank"}
                    >
                        <Button
                            onClick={() => {
                            }}
                            variant={"primary"}
                            icon={{
                                name: "dollar-white",
                                position: "left"
                            }}
                        >
                            {t("buttonSupport")}
                        </Button>
                    </Link>
                    {
                        isMenuOpen
                            ?
                            <img
                                className={"header__icon"}
                                src={BurgerClose}
                                alt="Burger icon"
                                onClick={() => setIsMenuOpen(false)}
                            />
                            :
                            <img
                                className={"header__icon"}
                                src={Burger}
                                alt="Burger icon"
                                onClick={() => setIsMenuOpen(true)}
                            />
                    }
                </div>
            </div>
            {
                isMenuOpen ?
                    <div className={"header__burger"}>
                        <Divider margin={"1rem"}/>
                        {navigation.map((item) => {
                            return (
                                <NavLink
                                    {...item}
                                    onClick={() => setIsMenuOpen(false)}
                                    isBurger={true}
                                />
                            )
                        })}
                    </div>
                    : null
            }
        </header>
    )
}

export default Header