import React from 'react'
import './Socials.scss'
import Instagram from 'assets/icons/instagram.svg'
import Telegram from 'assets/icons/telegram-social.svg'
import VK from 'assets/icons/vk-social.svg'
import { Link } from "react-router-dom"

interface ISocials {
    direction: "row" | "column"
}

const Socials: React.FC<ISocials> = (props) => {
    const css = ["socials"]
    css.push(`socials--${props.direction}`)

    return (
        <div className={css.filter(Boolean).join(" ")}>
            <Link
                to={"https://www.instagram.com/world.music.geek/"}
                target={"_blank"}
            >
                <img src={Instagram} alt="instagram"/>
            </Link>
            <Link
                to={"https://t.me/world_music_geek"}
                target={"_blank"}
            >
                <img src={Telegram} alt="telegram"/>
            </Link>
            <Link
                to={"https://vk.com/world_music_geek"}
                target={"_blank"}
            >
                <img src={VK} alt="vk"/>
            </Link>
        </div>
    )
}
export default Socials