import React from 'react'
import TibicenBlack from 'assets/tibicen_b.svg'
import { Link } from 'react-router-dom'
import "./Logo.scss"

const Logo = () => {
    return (
        <Link
            to={"/library"}
            className={"logo"}
        >
            <img src={TibicenBlack} alt="Tibicen Logo"/>
        </Link>
    )
}

export default Logo