import './StreamingList.scss'
import { UtilsStreamingsComponent } from "model"
import Streaming from "components/Streaming/Streaming"

const StreamingList: React.FC<Omit<UtilsStreamingsComponent, "id">> = (props) => {

    return (
        <div className={`streaming-list streaming-list--${props.spotify ? "exist" : "non-exist"}`}>
            {Object.entries(props)
                .filter((entries, index) => {
                    if (entries[0] === "id" || entries[0] === "spotify" || !entries[1]) {
                        return false
                    } else {
                        return true
                    }
                })
                .map((entries, index) => {
                    return (
                        <Streaming type={entries[0]} link={entries[1]} key={index}/>
                    )
                })
            }
        </div>
    )
}

export default StreamingList