import React from 'react'
import './Share.scss'
import Telegram from 'assets/icons/telegram-social.svg'
import VK from 'assets/icons/vk-social.svg'
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from 'react-i18next'

const Share = () => {
    const location = useLocation()
    const url = `https://tibicen.cc${location.pathname}`
    const { t } = useTranslation()

    return (
        <div className="share">
            <span>
                {t('share')}
            </span>
            <div className={"share__actions"}>
                <Link
                    className={"share__link"}
                    to={`https://t.me/share/url?url=${url}`}
                    target={"_blank"}
                >
                    <img
                        src={Telegram}
                        alt="Telegram icon"
                        width={32}
                        height={32}
                    />
                </Link>
                <Link
                    className={"share__link"}
                    to={`https://vk.com/share.php?url=${url}`}
                    target={"_blank"}
                >
                    <img
                        src={VK}
                        alt="VK icon"
                        width={32}
                        height={32}
                    />
                </Link>
            </div>
        </div>
    )
}

export default Share